import {
    useSearchParams
} from "next/navigation"
import {
    useRouter
} from "next/router"
import {
    useDispatch,
    useSelector
} from "react-redux"
import { HandleSongEnum } from "~config/constants/enums/handle-song.enum"
import {
    orderPath
} from "~config/constants/paths"
import { useCreateVisitorMutation } from "~screens/auth/services/authApi"
import {
    setSavedPath
} from "~screens/auth/store/authSlice"
import {
    resetOrderSteps,
    setArtistSelected,
    setCurrentArtist,
    setStep1FormData,
    setStep2FormData
} from "~screens/order/store/orderSlice"
import {
    setStartYourSongModal
} from "~screens/shared/store/sharedSlice"
// import { sendTaggingAddToCart } from "~services/tagging"

import { toast } from 'react-hot-toast';

function useStartSong() {


    const {
        push,
        asPath,
    } = useRouter()

    const dispatch
        = useDispatch()

    const [
        createVisitor,
        { isLoading }
    ] = useCreateVisitorMutation()

    const {
        step1FormData,
        step2FormData,
        step3FormData,
        step1FormData: {
            recipientName = ""
        },
    } = useSelector((state: any) => state.order)

    const { user } = useSelector((state: any) => state.auth)

    const continueSong = step1FormData.started

    const currentStep
        = step3FormData
            .existsInDB ?
            3
            :
            step2FormData
                .existsInDB ?
                2
                :
                step1FormData
                    .existsInDB ?
                    1
                    :
                    0


    const visitor = async () => {

        const response
            = await createVisitor({})

        const {
            error,
        }: any = response || {}

        if (error) {
            toast.error(error
                ?.data
                ?.message
            )
        }
    }
    const savePath = () => {

        dispatch(
            setSavedPath(
                asPath
            )
        )
    }

    const startSong = async ({ recipientName = "", }: any) => {

        if (!user)
            await visitor()

        savePath()

        if (continueSong)
            dispatch(setStartYourSongModal(true))// show dialog
        else {
            dispatch(resetOrderSteps({
                step1FormData: {
                    //  mentionOccasionInSong: false,
                    recipientName,
                    isAiSong: true,
                },
            }))

            dispatch(setStep1FormData({ isAiSong: true }))
            push(`${orderPath}step1/for-who`)
        }

        if ((window as any).gtag) {
            (window as any).gtag('event', 'start_song', {
                'event_category': 'Order',
                'event_label': 'from Hompage',
                'value': 0 // Optional: assign a value to this event
            });
        }
    }

    const startSongArtist = async (
        _id: string,
        firstName: string,
        lastName: string,
        stageName: string,
        pfpPath: string,
    ) => {

        if (!user)
            await visitor()

        savePath()

        dispatch(resetOrderSteps({
            isAiSong: false,
        }))
        dispatch(setArtistSelected(true))
        dispatch(
            setCurrentArtist({
                firstName,
                lastName,
                stageName,
                pfpPath,
            })
        )
        dispatch(setStep2FormData({
            artistIds: [_id],
            musicTraits: []
        }))

        dispatch(setStep1FormData({ isAiSong: false }))
        push(`${orderPath}step1/for-who`)




    }

    const startSongModal = async (
        isNew: boolean,
        onReset?: any,
    ) => {

        if (!user)
            await visitor()

        savePath()

        dispatch(setArtistSelected(false))

        if (isNew || !continueSong) {
            dispatch(resetOrderSteps({
                step1FormData: {
                    //     mentionOccasionInSong: false,
                    recipientName,
                    isAiSong: true,
                },
            }))

            dispatch(setStep1FormData({ isAiSong: true }))
            push(`${orderPath}step1/for-who`)



        } else {

            if (step3FormData?.existsInDB)
                push(`${orderPath}step4/review`)
            else if (step2FormData?.existsInDB)
                push(`${orderPath}step3/topics`)
            else if (step1FormData?.existsInDB) {
                if (step1FormData.isAiSong)
                    push(`${orderPath}step2/select-gender-ai`)
                else
                    push(`${orderPath}step2/pick-your-artist`)
            }
            else {
                push(`${orderPath}step1/for-who`)
            }



        }


        onReset?.()
    }

    const startSongHeader = async (type: HandleSongEnum) => {

        console.log("startSongHeader===>")

        if (type == HandleSongEnum.Continue)
            startSongModal(false);
        else if (type == HandleSongEnum.Checkout) {
            if (!user)
                await visitor()
            push(`${orderPath}step4/delivery-time/`)
        }
        else if (type == HandleSongEnum.Start)
            startSongModal(true)

        // sendTaggingAddToCart();
        // if ((window as any).gtag) {
        //     (window as any).gtag('event', 'start_song', {
        //         'event_category': 'Order',
        //         'event_label': type == HandleSongEnum.Continue ?
        //             'Header Continue Song'
        //             :
        //             type == HandleSongEnum.Checkout ?
        //                 'Header Continue Addons'
        //                 :
        //                 type == HandleSongEnum.Start ?
        //                     'Header Start Song'
        //                     :
        //                     '',
        //         'value': 0
        //     });
        // }

    }

    return {
        isLoading,
        currentStep,
        continueSong,
        startSong,
        startSongArtist,
        startSongModal,
        startSongHeader,
    }
}

export default useStartSong